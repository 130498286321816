import axios from 'axios';
import { axiosRequestInterceptor } from '@/logic/services/requestInterceptorService';
// import { axiosErrorInterceptor } from '@/logic/services/responseInterceptorService';

// We don't want to intercept a request error (only successful requests) so we pass undefined as the second parameter
axios.interceptors.request.use(axiosRequestInterceptor, undefined);
// We don't want to intercept a successful response (only responses with errors) so we pass undefined as the first parameter
// axios.interceptors.response.use(undefined, axiosErrorInterceptor);

export const httpGet = (url, data, withCredentials = false) => {
  return new Promise((resolve, reject) => {
    axios
      .get(url, { params: data, withCredentials })
      .then(r => resolve(r.data))
      .catch(e => {
        if (e.response && e.response.data) {
          reject(e.response.data);
        } else {
          reject(e);
        }
      });
  });
};

export const httpPost = (url, data, withCredentials = false) => {
  return new Promise((resolve, reject) => {
    axios
      .post(url, data, { withCredentials })
      .then(r => resolve(r.data))
      .catch(e => {
        if (e.response && e.response.data) {
          reject(e.response.data);
        } else {
          reject(e);
        }
      });
  });
};

export const httpPatch = (url, data, withCredentials = false) => {
  return new Promise((resolve, reject) => {
    axios
      .patch(url, data, { withCredentials })
      .then(r => resolve(r.data))
      .catch(e => {
        if (e.response && e.response.data) {
          reject(e.response.data);
        } else {
          reject(e);
        }
      });
  });
};
