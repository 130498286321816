import { getConfigurationValue } from '@/logic/helpers/utils';

const shouldaddClientId = url => {
  const microRoot = getConfigurationValue('api', '');
  if (url === `${microRoot}ferry-providers/availability/`) return true;
  if (url === `${microRoot}ferry-providers/pricing`) return true;
  return false;
};

export const axiosRequestInterceptor = config => {
  if (shouldaddClientId(config.url)) config.headers['Client-Id'] = 'WEB';
  return config;
};
